import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "./header/Header"
import Footer from "./footer/Footer"

import "../styles/index.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* Start Header Area */}
      <Header />
      {/* End Header Area */}

      {/* Start Main Content */}
      {children}
      {/* End Main Content */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
          <ScrollToTop showUnder={160}>
              <FiChevronUp />
          </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
